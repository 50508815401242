@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  --primary: #00bf73;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}
body.highlight {
  background-color: #00bf73 !important;
  color: #f4f4f4 !important;
}
.about-image {
  @apply w-full object-cover h-[25rem] lg:h-[35rem] rounded-[8px] transition-transform duration-300 ease-in-out transform hover:scale-110;
}
.nav-header {
  @apply font-[600] font-poppins text-[24px] text-primary leading-tight;
}

.nav-link {
  @apply font-[400] font-poppins text-[18px] text-dark leading-tight hover:bg-transparent hover:text-primary ms-4 min-w-[80px] py-2 px-3 cursor-pointer;
}

.footer-link {
  @apply font-[400] font-poppins text-[16px] text-dark leading-tight hover:bg-transparent hover:text-primary min-w-[80px]  cursor-pointer;
}

.custom-button {
  @apply h-[35px] md:h-[45px] min-w-[135px] text-[14px] font-poppins font-[500] rounded-[50px];
}

.section-heading {
  @apply text-2xl md:text-3xl lg:text-4xl font-bold w-full mb-2 text-primary leading-snug tracking-wider uppercase;
}

.section-title {
  @apply text-xl md:text-2xl lg:text-3xl font-semibold text-blue mb-2 leading-snug tracking-wider;
}

.section-text {
  @apply text-[16px] md:text-lg text-gray-600 leading-tight tracking-wider;
}

.carousel-icon-btn {
  @apply btn btn-circle bg-[#00bf73b6] text-white border-0 hover:bg-[#00bf73b6];
}
.active-nav,
.nav-link.active {
  @apply !text-primary md:text-center md:rounded-full;
}
.contact-input {
  @apply bg-transparent border  w-full p-2 h-[45px] rounded-md outline-none focus:border-primary;
}

.indeed-svg {
  height: 27px;
  width: 27px;
}
.indeed-svg:hover {
  fill: white;
}
.contact-input {
  @apply bg-transparent border  w-full p-2 h-[45px] rounded-md outline-none focus:border-primary;
}

.input {
  @apply bg-transparent border  w-full p-2 h-[45px] rounded-md outline-none focus:border-primary focus:outline-none;
}
.form-group {
  @apply col-span-12 md:col-span-6 my-2;
}
.form-label {
  @apply block text-dark text-[16px] font-[400] my-2;
}

.form-text {
  @apply inline-block text-gray-700 text-[14px] font-[400] ms-3;
}

.box-shadow-dark {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.form-group label {
  display: block;
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  margin: 8px 0;
}
input[type="file"] {
  padding: 8px;
}
input[type="text"] {
  padding: 8px;
}
input[type="radio"],
input[type="checkbox"] {
  margin-right: 8px;
  width: 16px;
  height: 16px;
  border: 1px solid gray;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

input[type="radio"] {
  border-radius: 50%;
}

.form-group label:has(input[type="radio"]) {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.form-group label:has(input[type="checkbox"]) {
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
  margin: 5px 0;
}

/* .images {
  position: relative;
  background-size: cover;
  background-position: center;
  height: calc(100vh - 90px);
  overflow: hidden;
  margin-top: 90px;
}

.images::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
      to bottom right,
      rgba(255, 140, 0, 0.3),
      rgba(75, 0, 130, 0.3)
    ),
    url("../public/assests/images/Luton-curtain1.webp");
  background-size: cover;
  background-position: center;
  transition: background-image 1s ease-out;
  z-index: 0;
  animation: imageSwap 15s infinite;
}

@keyframes imageSwap {
  0% {
    background-image: linear-gradient(
        to bottom right,
        rgba(255, 140, 0, 0.3),
        rgba(75, 0, 130, 0.3)
      ),
      url("../public/assests/images/Luton-curtain1.webp");
  }
  45% {
    background-image: linear-gradient(
        to bottom right,
        rgba(255, 140, 0, 0.3),
        rgba(75, 0, 130, 0.3)
      ),
      url("../public/assests/images/Luton-curtain1.webp");
  }
  50% {
    background-image: linear-gradient(
        to bottom right,
        rgba(0, 206, 209, 0.2),
        rgba(255, 105, 180, 0.2)
      ),
      url("../public/assests/images/pexels-quintingellar-2199293.jpg");
  }
  95% {
    background-image: linear-gradient(
        to bottom right,
        rgba(0, 206, 209, 0.2),
        rgba(255, 105, 180, 0.2)
      ),
      url("../public/assests/images/pexels-quintingellar-2199293.jpg");
  }
  100% {
    background-image: linear-gradient(
        to bottom right,
        rgba(255, 140, 0, 0.3),
        rgba(75, 0, 130, 0.3)
      ),
      url("../public/assests/images/Luton-curtain1.webp");
  }
}
.images-text {
  animation: fadeInOut 7.5s ease-in-out infinite;
  transition: 1s ease-in-out;
}
@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
} */

.box-shadow-dark {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
